import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'default-passive-events';
import scroll from 'vue-seamless-scroll';
import '@/assets/public/style/normalize.css';
import '@/assets/public/style/flex.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import less from 'less';
import print from '@/assets/public/utils/print.js';
import axios from 'axios';
import * as echarts from 'echarts';
import './plugins/echarts/map/js/china';
import MetaInfo from 'vue-meta-info';

import '@/assets/pc/style/quill.core.css';
import '@/assets/pc/style/quill.snow.css';
import '@/assets/pc/style/quill.bubble.css';
import vipPermission from '@/components/pc/vipPermission.vue';
import bindCompany from '@/components/pc/bindCompany.vue';

Vue.prototype.$echarts = echarts;
Vue.component('vipPermission', vipPermission);
Vue.component('bindCompany', bindCompany);
Vue.use(MetaInfo);
Vue.use(print);
Vue.use(less);
Vue.use(ElementUI);
Vue.use(scroll);
Vue.use(scroll, { componentName: 'scroll-seamless' });
Vue.config.productionTip = false;
console.log(window.sessionStorage.getItem('choosecity'))
if (!window.sessionStorage.getItem('choosecity')) {
  const whichCity = {
    code: '440100',
    name: '广州市',
    id: 1178
  };

  window.sessionStorage.setItem('choosecity', JSON.stringify(whichCity));
}
console.log('当前:', process.env.VUE_APP_BASEURL);
const getTime = () => {
  const yy = new Date().getFullYear();
  // let mm = new Date().getMonth()+1;
  // let mm = new Date().getMonth()+1;
  // let dd = new Date().getDate();
  // let hh = new Date().getHours();
  // let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
  // let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
  const getYear = yy;
  return getYear;
};
Vue.config.ignoredElements = [ 'wx-open-launch-weapp' ];
Vue.prototype.$year = getTime();

Vue.prototype.$sysType = 1;

Vue.prototype.$http = axios;

Vue.prototype.$baseURL = process.env.VUE_APP_BASEURL;
// Vue.prototype.$baseURL = 'http://192.168.11.174:9005'
// Vue.prototype.$baseURL ='https://javactst.po-o.cn',//测试库
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    // document.title = '申报通-政策服务云平台'
  }
}).$mount('#app');
