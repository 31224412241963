import axios from 'axios';
import Qs from 'querystring';
import router from '../router';

const key = 'DATA_USER';
export function request (config, method) {
  const instance = axios.create({
    // baseURL: 'https://javactst.po-o.cn', // 开发过程中换成此域名
    // baseURL: 'https://javactxt.po-o.cn', // 打包换成此域名
    // baseURL: 'https://javactst.po-o.cn', // 测试库
    // baseURL: 'http://192.168.11.145:9005', // 阿东
    // baseURL: 'http://192.168.11.174:9005', // 阿发
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 15000
  });

  instance.interceptors.request.use(function (config) {
    if (!method) {
      if (config.method === 'post' || config.method === 'put') {
        config.headers['Content-Type'] = encodeURI('application/x-www-form-urlencoded; charset=UTF-8');
        config.transformRequest = [ function (data) {
          data = Qs.stringify(data);
          return data;
        } ];
      }
    } else {
      if (config.method === 'post' || config.method === 'put') {
        config.headers['Content-Type'] = encodeURI('application/json; charset=UTF-8');
        config.headers['Page-Title'] = encodeURI(router.history.current.meta.title);
      }
    }
    if (window.localStorage.getItem(key) || window.sessionStorage.getItem(key)) {
      config.headers.Authorization = encodeURI(`${window.localStorage.getItem(key) || window.sessionStorage.getItem(key)}`);
      config.headers.token = encodeURI(`${window.localStorage.getItem(key) || window.sessionStorage.getItem(key)}`);
    }
    return config;
  }, function (error) {
    if (error.code === 'ECONNABORTED' || error.message === 'Network Error' || error.message.includes('timeout')) {
      this.$message.error('网络繁忙, 请稍后再试');
    }
    return Promise.reject(error);
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response) {
        switch (error.response.code) {
          case 501:
            router.push('/login');
        }
      }
      return Promise.reject(error.response.data);
    });

  return instance(config);
}
