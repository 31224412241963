<template>
  <div class="main">
    <div class="div-bottom">
      <div class="flex row between">
        <div class="flex" style="margin-top: 20px">
          <img src="../../assets/pc/images/index/titleLogo.png" alt="" />
          <div class="xinwenzhongxin">友情链接</div>
        </div>
      </div>

      <!--  友情链接  -->
      <div class="link flex row wrap">
        <div
          class="flex col al-start"
          v-for="(item, index) in linkList"
          :key="index"
        >
          <a :href="item.url" target="_blank">{{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/network";
export default {
  name: "footerArea",
  data() {
    return {
      linkList: [],
    };
  },
  mounted() {
    this.getLinkList();
  },
  methods: {
    jump(item) {
      if (item.name === "cooperation") {
        // this.$message.error('暂未开发此功能')
        this.$router.push({ name: "business" });
      } else if (item.name === "videoTutorial") {
        this.$router.push({ name: "videoTutorial" });
      } else {
        this.$store.commit("about/setStep", item.name);
        this.$router.push({ name: "about" });
      }
    },
    async getLinkList() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/friendLink/list",
      });
      this.linkList = res;
      // if (res.code !== 200) return this.$message.error("数据出错，请刷新");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 176px;
  margin: 0 auto;
  background-color: #f2f5fa;
}
.div-bottom {
  width: 1200px;
  // height: 422px;
  margin: 0 auto;
  background-color: #f2f5fa;
  .xinwenzhongxin {
    font-size: 20px;
    font-weight: bold;
    // color: #015293;
    // height: 31px;
    text-align: left;
    margin-left: 10px;
  }
}

.link {
  width: 100%;
  // height: 99px;
  // border: 1px solid #dfe1e4;
  position: relative;
  padding-top: 39px;
  justify-content: left;
}
.link > div {
  // height: 60px;
  line-height: 30px;
  width: 240px;
}
.link-top {
  position: absolute;
  top: -13px;
  width: 202px;
  height: 24px !important;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  background-color: #ffffff;
  border: 1.5px solid #dfe1e4;
  border-top: 0;
  border-bottom: 0;
}
.link > div a {
  text-decoration: none;
  color: rgba(7, 123, 209, 100);
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
}
.link > div a:hover {
  color: #777777;
}
.bottom {
  height: 100px;
  background-color: rgba(232, 236, 242, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-family: Roboto;
}
</style>
