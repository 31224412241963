<template>
  <div>
    <div class="gradual-area"></div>
    <div>
      <myMenu v-show="!institutions" />
      <div class="box" :style="pageWidth?'width:1200px':''">
        <router-view  />
      </div>
      <footerArea v-show="!institutions"/>
      <footerArea2 v-show="!institutions"/>
    </div>
    <sidebar v-if="$route.name !== 'memberManagement'"></sidebar>
  </div>
</template>

<script>
import menu from '@/components/pc/menu';
import footerArea from '@/components/pc/footerArea';
import footerArea2 from '@/components/pc/footerArea2';
import sidebar from '@/components/pc/sidebar';
import { mapState } from 'vuex';
export default {
  components: {
    myMenu: menu,
    footerArea: footerArea,
    footerArea2: footerArea2,
    sidebar
  },
  metaInfo: {
    title: '广州协会政策服务平台',
    meta: [
      {
        name: 'keywords',
        content: '高新技术企业认定,国家政策解读,专精特新申报条件,政府扶持项目,政府扶持项目有哪些,政策匹配,政策申报'
      },
      {
        name: 'description',
        content: '【广州协会政策服务平台官网】提供专业的「高新技术企业认定」「区块链扶持政策查询」「政府扶持项目查询」「专精特新申报条件查询」「政策匹配与申报」服务。'
      }
    ]
  },
  data () {
    return {
      institutions: false,
      show: false,
      pageWidth: true,
    };
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  computed: {
    ...mapState({
      isVip: (state) => state.login.isVip
    })
  },
  methods: {
    jump () {
      this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
    },
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  watch:{
    "$route.path": {
      handler(val) {
        if (val === "/index") {
          this.pageWidth = false;
        } else {
          this.pageWidth = true;
        }
      },
      immediate: true,
    },
  }
};
</script>

<style lang="less">
#app{
  .box{
    // width: 1200px;
    margin: 0 auto;
    background-image: linear-gradient(#1f73e1 5%, #f2f2f2 95%, #f2f2f2);
  }
}
.gradual-area {
  z-index: -9999;
  height: 700px;
  position: absolute;
  margin: 0 auto;
  background-image: linear-gradient(#1f73e1 10%, #f2f2f2 90%, #f2f2f2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.suspension{
  position: fixed;
  top: 50%;
  right:40px;
  z-index: 10;
}
.service{
  width:62px;
  height:54px;
  margin-top:10px;
  background:#fff;
  border-radius:5px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #333;
  border: 1px solid #E6E6E6;
  >div{
    margin-top: 5px;
  }
}
.goBackTop{
  width:62px;
  height:54px;
  margin-top:10px;
  background:#fff;
  border-radius:5px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #333;
  border: 1px solid #E6E6E6;
    >div{
    margin-top: 10px;
  }
}
// .sidebar{
//   position: fixed;
//   top: 50%;
//   right:40px;
//   z-index: 10;
//   transform: translate(0, -50%);
//   font-size: 12px;
//   .sidebar_menu{
//     width: 72px;
//     height: 320px;
//     border: 1px solid #E6E6E6;
//     background: #FFF;
//     border-radius: 5px;
//     .sidebar_item{
//       cursor: pointer;
//       height: 80px;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       img{
//         margin-bottom: 8px;
//       }
//     }
//   }
//   .go_top{
//     cursor: pointer;
//     margin-top: 10px;
//     width: 72px;
//     height: 72px;
//     border: 1px solid #E6E6E6;
//     background: #FFF;
//     border-radius: 5px;
//     text-align: center;
//     line-height: 72px;
//   }
// }
</style>
