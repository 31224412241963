import Vue from 'vue';
import Vuex from 'vuex';
import cidian from './cidian/cidian';
import push from './push/push';
import login from './login/login';
import organization from './organization/organization';
import policy from './policy/policy';
import personal from './personal/personal';
import menu from './components/menu';
import backstage from './backstage/backstage';
import agencies from './agencies/agencies';
import about from './about/about';
import simpleMatching from './simpleMatching/index';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cidian,
    push,
    login,
    organization,
    policy,
    personal,
    menu,
    backstage,
    agencies,
    about,
    simpleMatching
  }
});
