export default {
  namespaced: true,
  state: () => ({
    activeSteps: 1
  }),
  mutations: {
  },
  actions: {
  },
  getters: {}

};
