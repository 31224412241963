export const getToken = (key) => {
  const data = window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  if (data) {
    return data;
  } else {
    return null;
  }
};

export const getSetToken = (key, value, type) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  type ? window.localStorage.setItem(key, value) : window.sessionStorage.setItem(key, value);
};

export const remove = (key, type) => {
  type ? window.sessionStorage.removeItem(key) : window.localStorage.removeItem(key);
};

export const setInfoData = async (request, type = true) => {
  const { data: res } = await request({
    method: 'POST',
    url: '/pcp/getLoginInfo'
  });
  if (res.code === 200) {
    const { data: res1 } = await request({
      method: 'GET',
      url: `/pcp/PersonalCenter/accountInformation?id=${res.data.id}`
    });
    let isEnter = res1.data[0];
    // if (!isEnter.mobile && isEnter.entityId && isEnter.packageName) {
    if (!isEnter.mobile) {
      sessionStorage.setItem('isEnter', 'noEnter');
    } else {
      sessionStorage.setItem('isEnter', 'enter');
    }
    if (type) {
      window.localStorage.setItem('userinfo', JSON.stringify(res.data));
    } else {
      window.sessionStorage.setItem('userinfo', JSON.stringify(res.data));
    }
    return Promise.resolve('islogin');
  } else {
    if (type) {
      window.localStorage.removeItem('DATA_USER');
      window.localStorage.removeItem('userinfo');
      // this.$router.push('/login')
    } else {
      window.sessionStorage.removeItem('DATA_USER');
      window.sessionStorage.removeItem('userinfo');
      // this.$route.push('/login')
    }
    return Promise.resolve('logout');
  }
};

export const getVip = async (request) => {
  const { data: res } = await request({
    method: 'GET',
    url: '/pcp/PersonalCenter/accountInformation',
    params: {
      id: getMemberId()
    }
  });
  if (res.code === 200) {
    if (res.data.length > 0) {
      // if (res.data[0].entityId) {
      // }
      const { entityId, memberId, name } = res.data[0];
      const localUserEntity = localStorage.getItem('userEntity');
      localStorage.setItem('mainName', JSON.stringify(res.data[0].name));
      if ((localUserEntity && JSON.parse(localUserEntity).entityId !== entityId) || !localUserEntity) {
        localStorage.setItem('userEntity', JSON.stringify({ entityId: entityId, memberId: memberId, entityName: name }));
      }
      if (!res.data[0].packageName) {
        return '普通用户';
      } else {
        return '正式会员';
      }
    }
  }
};

export function getMemberId () {
  if (window.localStorage.getItem('userinfo') || window.sessionStorage.getItem('userinfo')) {
    if (JSON.parse(window.localStorage.getItem('userinfo'))) {
      return JSON.parse(window.localStorage.getItem('userinfo')).id;
    } else {
      return JSON.parse(window.sessionStorage.getItem('userinfo')).id;
    }
  } else {
    return '';
  }
}

export function getEntityId () {
  if (window.localStorage.getItem('userinfo') || window.sessionStorage.getItem('userinfo')) {
    if (JSON.parse(window.localStorage.getItem('userinfo'))) {
      return JSON.parse(window.localStorage.getItem('userinfo')).entityId;
    } else {
      return JSON.parse(window.sessionStorage.getItem('userinfo'));
    }
  } else {
    return '';
  }
}

export function getMobile () {
  if (window.localStorage.getItem('userinfo') || window.sessionStorage.getItem('userinfo')) {
    if (JSON.parse(window.localStorage.getItem('userinfo'))) {
      return JSON.parse(window.localStorage.getItem('userinfo')).mobile;
    } else {
      return JSON.parse(window.sessionStorage.getItem('userinfo')).mobile;
    }
  } else {
    return '';
  }
}
export const getnoreadmessage = async (request,memberId) => {
  const { data: res } = await request({
    method: 'GET',
    url: '/pcp/intelligentPush/getnoreadmessage',
    params: {
      memberId: memberId
    }
  });
  if (res.code === 200) {
    localStorage.setItem("notRead", res.data);
    return res.data;
  }
};
