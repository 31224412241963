export default {
  namespaced: true,
  state: () => ({
    step: 'enterprise'
  }),
  mutations: {
    setStep (state, data) {
      state.step = data;
    }
  },
  actions: {
  },
  getters: {}
};
