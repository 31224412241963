export default {
  namespaced: true,
  state: () => ({
    sonUnboundDialogState: false,
    addSonDialogState: false,
    mainUnboundHasSonState: false,
    sonUnboundSelfDialogState: false,
    readStatus: '0',
    readNum: '已读',
    imgUrl: '',
    notRead: '',
    countObj:{}
  }),
  mutations: {
    setSonUnboundDialogState(state) {
      state.sonUnboundDialogState = !state.sonUnboundDialogState;
    },
    setAddSonDialogState(state) {
      state.addSonDialogState = !state.addSonDialogState;
    },
    setMainUnboundHasSonState(state) {
      state.mainUnboundHasSonState = !state.mainUnboundHasSonState;
    },
    setSonUnboundSelfDialogState(state) {
      state.sonUnboundSelfDialogState = !state.sonUnboundSelfDialogState;
    },
    setReadStatus(state, status) {
      state.readStatus = status;
    },
    setReadNum(state, num) {
      state.readNum = num;
    },
    setImgUrl(state, avatar) {
      state.imgUrl = avatar;
    },
    setNotRead(state, notRead) {
      state.notRead = notRead;
    },
    setCountObj(state, countObj) {
      state.countObj = countObj;
    }
  },
  actions: {
  },
  getters: {
  }
};
