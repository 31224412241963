<template>
  <div class="sidebar">
    <div class="sidebar_menu">
      <el-dropdown style="cursor: pointer;" @click="toTop" placement="left">
        <div class="sidebar_item">
          <img src="@/assets/pc/images/public/customer.png" alt="">
          <div>微信客服</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <div style="text-align:center;padding: 20px;">
            <img style="width: 100px;height: 100px;" :src="weChatCustomer" alt="">
            <div style="font-size: 14px;margin-top: 6px;">微信扫一扫联系我们</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- <el-dropdown style="cursor: pointer;" @click="toTop" placement="left">
        <div class="sidebar_item">
          <img src="@/assets/pc/images/public/QQ.png" alt="">
          <div>QQ客服</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <div style="text-align:center;padding: 20px;">
            111
          </div>
        </el-dropdown-menu>
      </el-dropdown> -->

      <el-dropdown style="cursor: pointer;" @click="toTop" placement="left">
        <div class="sidebar_item">
          <img src="@/assets/pc/images/public/phone.png" alt="">
          <div>联系电话</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <div style="text-align:center;padding: 20px;">
            <div style="font-size: 14px;">联系电话</div>
            <div style="color: #156ED0;font-size: 18px;margin-top: 6px;">{{ phone }}</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown style="cursor: pointer;" @click="toTop" placement="left">
        <div class="sidebar_item">
          <img src="@/assets/pc/images/public/weChat.png" alt="">
          <div>微信公众号</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <div style="text-align:center;padding: 20px;">
            <img style="width: 100px;height: 100px;" :src="publicAccount" alt="">
           <div style="font-size: 14px;margin-top: 6px;">扫码关注我们</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
    <div class="go_top" @click="toTop">
      <img src="@/assets/pc/images/public/goTop.png" alt="">
    </div>
  </div>
</template>

<script>
import { getMemberExclusivePhone, getQQ, getWechat, getWechatOfficialAccount } from '../../api/simple_matching/index'
export default {
  metaInfo: {
    title: '广州协会政策服务平台',
    meta: [
      {
        name: 'keywords',
        content: '高新技术企业认定,国家政策解读,专精特新申报条件,政府扶持项目,政府扶持项目有哪些,政策匹配,政策申报'
      },
      {
        name: 'description',
        content: '【广州协会政策服务平台官网】提供专业的「高新技术企业认定」「区块链扶持政策查询」「政府扶持项目查询」「专精特新申报条件查询」「政策匹配与申报」服务。'
      }
    ]
  },
  data () {
    return {
      weChatCustomer: undefined,
      // QQ: undefined,
      phone: undefined,
      publicAccount: undefined
    };
  },
  mounted () {
    this.getPhone();
    // this.getQQNumber();
    this.getWechatCustomer();
    this.getPublicAccount();
  },
  methods: {
    getPhone () {
      getMemberExclusivePhone().then(res => {
        const { code, data } = res.data;
        if (code === 200) {
          this.phone = data;
        }
      });
    },
    // getQQNumber () {
    //   getQQ().then(res => {
    //     const { code, data } = res.data;
    //     if (code === 200) {
    //       this.QQ = data;
    //     }
    //   });
    // },
    getWechatCustomer () {
      getWechat().then(res => {
        const { code, data } = res.data;
        if (code === 200) {
          this.weChatCustomer = data;
        }
      });
    },
    getPublicAccount () {
      getWechatOfficialAccount().then(res => {
        const { code, data } = res.data;
        if (code === 200) {
          this.publicAccount = data;
        }
      });
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  },
};
</script>

<style lang="less" scoped>
.sidebar{
  position: fixed;
  top: 50%;
  right:40px;
  z-index: 10;
  transform: translate(0, -50%);
  font-size: 12px;
  .sidebar_menu{
    width: 72px;
    height: 240px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .sidebar_item{
      width: 72px;
      cursor: pointer;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      img{
        margin-bottom: 8px;
      }
    }
  }
  .go_top{
    cursor: pointer;
    margin-top: 10px;
    width: 72px;
    height: 72px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #FFF;
    border-radius: 5px;
    text-align: center;
    line-height: 72px;
  }
}
::v-deep .el-tooltip__popper.is-light{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border: none !important;
}
</style>
