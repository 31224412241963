<template>
    <div class="cover" :style="{'backdrop-filter': type ? `blur(8px)` : ''}">
      <div v-if="type" class="project">
        <div class="header">
          <div>
            <div class="title">
              <div>VIP</div>
              <div>会员专属功能</div>
            </div>
            <div style="font-size: 14px;line-height: 20px;color: rgb(218, 218, 218);">
              联系客服成为会员，查看申报项目详细内容
            </div>
          </div>
          <div class="phone">
            <img src="@/assets/pc/images/permission/phone.png" alt="">
            <span>{{ phone }}</span>
          </div>
        </div>
        <div class="body">
          <img src="@/assets/pc/images/permission/project3.png" alt="">
        </div>
      </div>
      <div v-if="!type" class="container">
        <div class="header">
          <img src="../../assets/pc/images/permission/riLine-vip-line 1@1x.png" alt="">
          <!-- <div class="hd_cont_box">
            <div> -->
              <div>会员专属功能</div>
              <!-- <div class="hd_text">联系客服成为会员，查看申报项目详细内容</div>
            </div> -->
            <!-- <div style="display: flex;justify-content: center;align-items: center;">
              <el-button type="primary" class="phone_btn" plain>
                <img style="width: 20px; height: 20px; vertical-align: middle;" src="../../assets/pc/images/permission/md-phone_in_talk@1x.png" alt="">
                  020-62902130
              </el-button>
            </div>
          </div> -->
        </div>
        <div class="body">
          <!-- <img src="" alt=""> -->
          <div class="content">
            <p>抱歉，该功能只对会员开放</p>
            <p>联系客服成为会员，查看申报项目详细内容</p>
            <!-- <p>您可以随时成为我们的会员，以享受这些特权</p> -->
            <div style="display: flex;justify-content: center;align-items: center;">
              <el-button type="primary" class="phone_btn" plain icon="el-icon-phone-outline">{{ phone }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { getMemberExclusivePhone } from '../../api/simple_matching/index';
export default {
  props: {
    vipPermissionVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: [ Number,String ],
      default: 0
    }
  },
  data () {
    return {
      // maskObj:{
      //   1: 'https://cdn.po-o.cn/Upload/BusinessLicense/20230712b2482a7a444343d1a8aeebfa4ca91394.png',//政策
      //   2: 'https://cdn.po-o.cn/Upload/BusinessLicense/20230712c8559fadb9bb430ab2c99cac9906c90b.png',//项目匹配
      //   3: 'https://cdn.po-o.cn/Upload/BusinessLicense/2023071313379645f60341be95bf30cda6b5bc0f.png',//项目规划
      //   4: 'https://cdn.po-o.cn/Upload/BusinessLicense/2023071333bf8c2d552041828128b4107cfebfb4.png',//申报计划
      // },
      // tipObj:{
      //   1: 'https://cdn.po-o.cn/Upload/BusinessLicense/project3.png',//政策
      //   2: 'https://cdn.po-o.cn/Upload/BusinessLicense/20230721cf745fc34c444dcf9d8f3d9e0dff4c81.png',//项目匹配
      //   3: 'https://cdn.po-o.cn/Upload/BusinessLicense/2023072168e2a9c5e16744a1a23ac1c900a85bd1.png',//项目规划
      //   4: 'https://cdn.po-o.cn/Upload/BusinessLicense/202307211294b5b679604970ab7944eb3a8ff190.png',//申报计划
      // },
      phone: undefined,
    };
  },
  mounted () {
    getMemberExclusivePhone().then(res => {
      if (res.data.code === 200) this.phone = res.data.data;
    });
  }
};
</script>

<style lang="less" scoped>
.tip_img{
  width: 80%;
  /* height: 730px; */
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%,0);
}
.cover{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url('https://po-o-cn.oss-cn-shenzhen.aliyuncs.com/2022/12/21/4002da076a294a70ba114cbebb8f3b2c.png') no-repeat 0/100% 100% ; */
  /* background: rgba(255,255,255,0.5); */
  /* backdrop-filter: blur(1px); */
  z-index: 9;
  .project{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    background: #156ed0;
    width: 600px;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    .header{
      height: 120px;
      width: 600px;
      background: #156ed0;
      background-image: url('../../assets/pc/images/permission/permission_pg.png');
      background-size: 105%;
      background-repeat: repeat-x;
      background-position: bottom center;
      border-radius: 10px 10px 0 0;
      color: #FFF;
      font-size: 16px;
      line-height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &>div:first-child{
        margin-left: 20px;
      }
      .title{
        display: flex;
        align-items: center;
        font-size: 24px;
        &>div:first-child{
          border-top: 3px solid #FFF;
          border-bottom: 3px solid #FFF;
          height: 24px;
          line-height: 24px;
          font-size: 20px;
          margin-right: 12px;
        }
      }
      .phone{
        height: 50px;
        width: 220px;
        background: #6290da;
        // opacity: 0.3;
        border-radius: 10px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        span{
          -webkit-user-select: text;
          -khtml-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
        }
      }
    }
    .body{
      background: #fff;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }
  }
}
.container{
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%,0);
  z-index: 9;
  min-width: 500px;
  border-radius: 10px;
  box-shadow: 2px 2px 50px rgba(0,0,0,0.5);
  background: linear-gradient(90deg,#5c99ff,#3179f0);
  padding: 20px;
}
.header{
  /* height: 54px; */
  background: no-repeat 0/100% 100% url('../../assets/pc/images/permission/permission_pg.png') ;
  border-radius: 10px 10px 0 0;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
}
.header img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.hd_cont_box{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.hd_text{
  font-size: 14px;
  font-weight: normal;
}
.body{
  background: linear-gradient(90deg,#5c99ff,#3179f0);
  border-radius: 0 0 10px 10px;
}
.content{ 
  padding: 30px 20PX;
  background: #FFF;
  border-radius: 8px;
  
}
p{
  text-align: center;
  color: #000;
  margin-bottom: 12px;
  font-size: 16px;
}
/* p:last-child{
  text-align: center;
} */
.phone_btn {
  color: #156ed0;
  font-size: 20px;
  background-color: #f2f5fa;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>