
export class SessionUtils {
  static get (key) {
    const item = window.sessionStorage.getItem(key);
    return item !== 'undefined' ? JSON.parse(window.sessionStorage.getItem(key)) : null;
  }

  static set (key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  static remove (key) {
    window.sessionStorage.removeItem(key);
  }
}
