<template>
  <div>
    <header class="header">
      <div class="top" :class="headBottom ? 'top-bgImg' : 'top-bgCol '">
        <div class="top-box flex between">
          <div class="box-left">
            <div class="flex">
              <img
                style="cursor: pointer"
                src="../../assets/pc/images/menu/headLogo.png"
                alt=""
                @click="toIndex"
              />
              <a href="http://www.gzoutsourcing.cn/">返回平台首页></a>
            </div>
            <div class="box-left-seach" v-if="headBottom">
              <span>
                <img
                  src="~@/assets/pc/images/index/search-icon.svg"
                  style="width: 24px; height: 24px"
                />
                <span style="margin-left: 6px">搜政策</span>
              </span>
              <div class="flex">
                <el-input
                  placeholder="输入关键词搜索政策"
                  v-model="policySearch"
                >
                </el-input>
                <el-button slot="append" @click="btnSearch">搜索</el-button>
              </div>

              <div>
                热门搜索:
                <span
                  v-for="(item, index) in searchData"
                  :key="index"
                  @click="listSearch(item.name)"
                  >{{ item.name }}</span
                >
                <!-- <span @click="listSearch">人才补贴</span>
                <span @click="listSearch">高企申报</span> -->
              </div>
            </div>
          </div>
          <div class="box-right">
            <div class="right-top">
              <div
                style="margin-left: 40px; cursor: pointer"
                @click="handleJumpMessage"
              >
                <el-badge
                  :value="notRead"
                  class="item"
                  :hidden="notRead > 0 ? false : true"
                >
                  <i class="el-icon-message"></i>
                  <span style="margin-left: 6px">我的消息</span>
                </el-badge>
              </div>
              <el-dropdown v-if="login" class="avatar">
                <span class="el-dropdown-link">
                  <div class="flex avatar-login">
                    <i class="el-icon-user-solid"></i>
                    <span>{{ loginMobile }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </span>

                <el-dropdown-menu
                  slot="dropdown"
                  class="user"
                  style="padding: 10px; margin-right: -20px"
                >
                  <el-dropdown-item @click.native="goToPerCenter"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goToPerCenter">
                    完善资料</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goToPerCenterAccount">
                    账号管理</el-dropdown-item
                  >
                  <!-- <el-dropdown-item @click.native="goToPerCenterActivity">
                    我的活动</el-dropdown-item
                  > -->
                  <el-dropdown-item @click.native="logOut">
                    退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="login" v-else>
                <i class="el-icon-user-solid"></i>
                <router-link to="/login">登录</router-link> |
                <router-link to="/login/register">注册</router-link>
              </div>
            </div>
            <div
              class="flex wrap right-bottom"
              v-if="headBottom && statisticsData"
            >
              <div class="rectangle flex row between">
                <div class="circle1 flex around">
                  <div>
                    <img
                      src="~@/assets/pc/images/index/circle5.svg"
                      style="width: 28px; height: 25px"
                    />
                  </div>
                  <span style="margin-left: 6px">政策文件</span>
                </div>
                <div>
                  {{ statisticsData.policyFile
                  }}<span style="margin-left: 6px">份</span>
                </div>
              </div>
              <div class="rectangle flex row between">
                <div class="circle2 flex around">
                  <div>
                    <img
                      src="~@/assets/pc/images/index/circle8.svg"
                      style="width: 28px; height: 25px"
                    />
                  </div>
                  <span style="margin-left: 6px">扶持项目</span>
                </div>
                <div>
                  {{ statisticsData.supportingProjects
                  }}<span style="margin-left: 6px">个</span>
                </div>
              </div>
              <div class="rectangle flex row between">
                <div class="circle3 flex around">
                  <div>
                    <img
                      src="~@/assets/pc/images/index/circle7.svg"
                      style="width: 28px; height: 25px"
                    />
                  </div>
                  <span style="margin-left: 6px">申报通知</span>
                </div>
                <div>
                  {{ statisticsData.declarationNotice
                  }}<span style="margin-left: 6px">份</span>
                </div>
              </div>
              <div class="rectangle flex row between">
                <div class="circle4 flex around">
                  <div>
                    <img
                      src="~@/assets/pc/images/index/circle6.svg"
                      style="width: 28px; height: 25px"
                    />
                  </div>
                  <span style="margin-left: 6px">申报中项目</span>
                </div>
                <div>
                  {{ statisticsData.itemsUnderApplication
                  }}<span style="margin-left: 6px">个</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #f2f5fa">
        <ul class="menu">
          <li :class="{'selected_text': isIndex}">
            <router-link to="/index" active-class="active">首页</router-link>
          </li>
          <li :class="{'selected_text': isPolicy}">
            <router-link to="/policy">政策文库</router-link>
          </li>
          <li :class="{'selected_text': isCidian}">
            <router-link to="/cidian">政策词典</router-link>
          </li>
          <li :class="{'selected_text':currentPath === '/simpleMatching'}">
            <router-link to="/simpleMatching">政策匹配</router-link>
          </li>
          <li :class="{'selected_text':currentPath === '/push'}">
            <router-link to="/push">智能推送</router-link>
          </li>
          <li :class="{'selected_text':currentPath === '/eventServices'}">
            <router-link to="/eventServices">活动服务</router-link>
          </li>
          <!-- <li :class="{'selected_text':currentPath === '/member'}">
            <router-link to="/member">申报管家</router-link>
          </li> -->
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import { request } from "@/network";
import getLogin from "@/mixins/getLogin.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import {
  getMobile,
  getMemberId,
  getVip,
  setInfoData,
} from "@/assets/public/utils/token";
import headpic from "@/assets/pc/images/menu/headpic.png";
export default {
  mixins: [getLogin],
  components: {},
  data() {
    return {
      input: null,
      login: this.userToken !== null,
      // activeName: 'hot',
      isOrgBool: false,
      city: [
        {
          code: "440100",
          name: "广州市",
          id: 1178,
        },
      ],
      loginMobile: "",
      headpic: headpic,
      vipOrNot: "普通用户",
      // headpicWechat: JSON.parse(localStorage.getItem('userinfo')).avatar,
      headpicWechat: "",
      headPortrait: "",
      notRead: "",
      tabs: [],
      activeName: "广州市",
      selectCity: "广州市", // 默认是广州
      readShow: false,
      statisticsData: {},
      headBottom: true,
      policySearch: "",
      searchData: [
        { name: "专精特新" },
        { name: "人才补贴" },
        { name: "小微企业" },
      ],
      indexPath: [ '/index', '/indexpolicy', '/eventServices/activityDetails', '/newsdetail', '/newscenter' ],
      policyPath: [ '/policy', '/tongzhi', '/fagui', '/faguidetails', '/formaldetails', '/policySearchNotice', '/policySearchReg' ],
      cidianPath: [ '/cidian', '/cidian/nounexplain' ]

    };
  },
  activated() {
    let localSelectCity = localStorage.getItem("selectCity");
    if (localSelectCity) {
      this.selectCity = localSelectCity; // 因为部分页面使用了缓存所以需要把city值重新填充
    }
  },
  mounted() {
    // console.log(window.location.pathname);
    // 因为组件离开页面会摧毁组件
    let localSelectCity = localStorage.getItem("selectCity");
    if (localSelectCity) {
      this.selectCity = localSelectCity;
    }
    this.getLetterArea().then(() => {
      this.createdData().then(() => {
        this.mountedData();
      });
    });
    this.getnoreadmessage();
  },
  computed: {
    ...mapGetters({
      getVuexUser: "login/getUser",
    }),
    ...mapState({
      userToken: (state) => state.login.user,
      userMobile: (state) => state.login.getUserMobile,
      isVip: (state) => state.login.isVip,
      headPic: (state) => state.login.headPic,
      imgUrl: (state) => state.personal.imgUrl,
      readNum: (state) => state.personal.readNum,
      avatar: (state) => state.personal.imgUrl,
      readNot: (state) => state.personal.notRead,
      countObj: (state) => state.personal.countObj,
    }),
    currentPath () {
      return this.$route.matched[1].path;
    },
    isIndex () {
      if (this.indexPath.includes(this.currentPath)) {
        return true;
      }
      return false;
    },
    isPolicy () {
      if (this.policyPath.includes(this.currentPath)) {
        return true;
      }
      return false;
    },
    isCidian () {
      if (this.cidianPath.includes(this.currentPath)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("menu", ["setCity"]),
    ...mapMutations("simpleMatching", ["setComponentName"]),
    async createdData() {
      // this.$store.commit('menu/setSelectCity', this.selectCity)
      if (window.sessionStorage.getItem("choosecity")) {
        this.setCity(JSON.parse(window.sessionStorage.getItem("choosecity")));
        this.whichCity = JSON.parse(
          window.sessionStorage.getItem("choosecity")
        );
      } else {
        this.whichCity = {
          code: "440100",
          name: "广州市",
          id: 1178,
        };
        window.sessionStorage.setItem(
          "choosecity",
          JSON.stringify(this.whichCity)
        );
        this.setCity(this.whichCity);
      }
      this.login = this.mixinGetLogin();
      this.loginMobile = getMobile();
      if (getMemberId() !== "") {
        getVip(request).then((res) => {
          this.vipOrNot = res;
        });
      }
    },
    async mountedData() {
      setInfoData(request).then((res) => {
        if (res === "logout") {
          if (window.sessionStorage.getItem("DATA_USER")) {
            window.sessionStorage.removeItem("DATA_USER");
            window.sessionStorage.removeItem("userinfo");
            localStorage.removeItem("notRead");
          } else {
            window.localStorage.removeItem("DATA_USER");
            window.localStorage.removeItem("userinfo");
            localStorage.removeItem("notRead");
          }
        }
      });
    },
    async getSearchCount(value) {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/librarysearch/getSearchCount",
        data: {
          city: localStorage.getItem("selectCity"),
          keyWord: value,
          uuidList: "",
          isEnd: "",
        },
      });
      if (res.code !== 200) return this.$message.error("数据出错，请刷新");
      const { notice, policy } = res.data;
      this.goChildPage(value, notice, policy);
    },
    goChildPage(value, notice, policy) {
      this.$router.push({
        path: "/policySearchNotice",
        query: {
          keyWord: value,
          notice: notice,
          policy: policy,
          type: 1,
          previous: true,
          page:'首页'
        },
      });
    },
    btnSearch() {
      this.getSearchCount(this.policySearch);
    },
    listSearch(value) {
      this.getSearchCount(value);
    },
    toIndex() {
      this.$router.push("/index");
    },
    handleClick($event) {
      this.activeName = $event.target.getAttribute("name");
      const arr = Array.from($event.target.parentElement.children);
      arr.forEach((item, i) => {
        item.classList.remove("active");
      });
      $event.target.classList.add("active");
    },
    handleClickMatch(type) {
      if (type === "simple") {
        this.$router.push("/simpleMatching");
        this.setComponentName("Search");
      } else {
        this.$router.push("/matching");
        // this.$confirm('开发中，敬请期待', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'success'
        // })
      }
    },
    async logOut() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/logout",
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success("退出登录成功");
      window.sessionStorage.removeItem("isEnter");
      if (window.sessionStorage.getItem("DATA_USER")) {
        window.sessionStorage.removeItem("DATA_USER");
        window.sessionStorage.removeItem("userinfo");
        localStorage.removeItem("notRead");
      } else {
        window.localStorage.removeItem("DATA_USER");
        window.localStorage.removeItem("userinfo");
        localStorage.removeItem("notRead");
      }
      localStorage.removeItem('mainName');
      this.$store.commit("personal/setReadNum", 0);
      this.login = this.mixinGetLogin();
      this.$router.push("/index");
    },
    goToPerCenter() {
      this.$router.push("/personal");
    },
    goToPerCenterAccount() {
      this.$router.push("/personal/account");
    },
    goToPerCenterActivity() {
      this.$router.push("/personal/activity");
    },
    chooseCity(city) {
      // console.log(city);
      this.setCity(city);
      this.whichCity = city;
      window.sessionStorage.setItem(
        "choosecity",
        JSON.stringify(this.whichCity)
      );
      // window.location.reload();
    },
    handleInstitutions() {
      // window.location.href = 'http://localhost:8081/#/policy/tongzhi'
      this.$router.push({
        // name:'backstage'
        name: "agencies",
      });
    },
    // 点击服务机构跳转判定
    toServiceRoute() {
      // orgStatus 1 审核中 2 审核通过第一次进入系统 3、审核通过机构正常状态 4、审核不通过 5、审核通过机构被禁用
      const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
      if (!userinfo) {
        this.$message.warning("请登录用户");
        this.$router.push({ name: "loginbyverification" });
        return;
      }
      const arr = { null: 0, 1: 2, 2: 3, 4: 4 };
      userinfo &&
        userinfo.orgStatus !== 3 &&
        userinfo.orgStatus !== 5 &&
        this.$router.push("/agencies") &&
        this.$store.commit("agencies/changeStepNum", arr[userinfo.orgStatus]);
      userinfo && userinfo.orgStatus === 3
        ? this.$router.push({ name: "information" })
        : userinfo.orgStatus === 5
        ? this.$alert(
            '账号暂不能使用，请联系客服<span style="color:rgba(48, 120, 240, 1);margin-left:6px;">400-658-1139</span>',
            "提示",
            { type: "warning", dangerouslyUseHTMLString: true }
          )
        : "";
    },
    async getnoreadmessage() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/intelligentPush/getnoreadmessage",
        params: {
          memberId: getMemberId(),
        },
      });
      if (res.code !== 200) return this.$message.error("未读消息获取失败");
      this.notRead = res.data;
      localStorage.setItem("notRead", res.data);
      return Promise.resolve();
    },
    handleJumpMessage() {
      this.$router.push({ path: "/personal/info" });
    },
    async getLetterArea() {
      const cityTabs = JSON.parse(sessionStorage.getItem("cityTabs"));
      this.tabs = cityTabs;
      if (cityTabs.length > 0) return;
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/arealetter/getNewLetterArea",
      });
      if (res.code !== 200) this.$message.error("获取地域城市失败");
      // 全国城市首字母一共有19个，5个为一组 4组
      const allCity = [
        { name: "hotCity", children: [] },
        { name: "", children: [] },
        { name: "", children: [] },
        { name: "", children: [] },
        { name: "", children: [] },
      ];
      allCity[0].children = res.data.hotAdress;
      res.data.list.forEach((v, i) => {
        if (i <= 4) {
          allCity[1].name += v.mame;
        } else if (i > 4 && i <= 9) {
          if (i <= 4) {
            allCity[1].name += v.mame;
          } else {
            allCity[2].name += v.mame;
          }
        } else if (i > 9 && i <= 14) {
          if (i <= 4) {
            allCity[1].name += v.mame;
          } else if (i > 4 && i <= 9) {
            allCity[2].name += v.mame;
          } else {
            allCity[3].name += v.mame;
          }
        } else {
          if (i <= 4) {
            allCity[1].name += v.mame;
          } else if (i > 4 && i <= 9) {
            allCity[2].name += v.mame;
          } else if (i > 9 && i <= 14) {
            allCity[3].name += v.mame;
          } else {
            allCity[4].name += v.mame;
          }
        }
      });
      for (let index = 0; index < res.data.list.length; index++) {
        if (allCity[1].name.indexOf(res.data.list[index].mame) > -1) {
          res.data.list[index].child.forEach((v) => {
            allCity[1].children.push(v);
          });
        } else if (allCity[2].name.indexOf(res.data.list[index].mame) > -1) {
          res.data.list[index].child.forEach((v) => {
            allCity[2].children.push(v);
          });
        } else if (allCity[3].name.indexOf(res.data.list[index].mame) > -1) {
          res.data.list[index].child.forEach((v) => {
            allCity[3].children.push(v);
          });
        } else if (allCity[4].name.indexOf(res.data.list[index].mame) > -1) {
          res.data.list[index].child.forEach((v) => {
            allCity[4].children.push(v);
          });
        }
      }
      allCity.forEach((v) => {
        if (v.children.length > 0) {
          this.tabs.push(v);
          sessionStorage.setItem("cityTabs", JSON.stringify(this.tabs));
        }
      });
      const localSeleCity = localStorage.getItem("selectCity");
      if (
        localSeleCity === null ||
        localSeleCity === undefined ||
        localSeleCity === ""
      ) {
        this.selectCity = "广州市" || res.data.currentAddress;
        localStorage.setItem("selectCity", "广州市" || res.data.currentAddress);
      } else {
        this.selectCity = localSeleCity;
      }
      this.$store.commit("menu/setSelectCity", this.selectCity);
    },
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户信息失败");
    },
    handleChooseCity(v) {
      // console.log(v);
      let city = {
        name: v.name,
        id: v.id,
        code: v.code,
      };
      this.chooseCity(city);
      this.selectCity = v.name;
      localStorage.setItem("selectCity", v.name);
      this.$store.commit("menu/setSelectCity", v.name);
    },
    handleJumpImg() {
      if (JSON.parse(localStorage.getItem("userinfo"))) {
        if (
          JSON.parse(localStorage.getItem("userinfo")).entityId ||
          JSON.parse(sessionStorage.getItem("userinfo"))
        ) {
          this.$message({
            message: "已向客服发送请求成为协会会员通知",
            type: "success",
          });
        } else {
          this.$message.error("您未绑定主体，请先绑定主体");
        }
      } else {
        this.$message.error("未登录，请登录后再操作");
      }
    },
  },
  watch: {
    "$route.path": {
      handler(val) {
        if (val === "/index") {
          this.headBottom = true;
        } else {
          this.headBottom = false;
        }
      },
      immediate: true,
    },
    userToken(newVal) {
      this.login = newVal !== null;
    },
    userMobile() {
      // this.getnoreadmessage();
      this.loginMobile = getMobile();
    },
    isVip: {
      handler(newVal) {
        this.vipOrNot = newVal;
      },
      immediate: true,
    },
    imgUrl: {
      handler(newVal) {
        this.headpicWechat = newVal;
      },
      immediate: true,
    },
    readNot: {
      handler(newVal) {
        this.notRead = newVal;
      },
      immediate: true,
    },
    countObj: {
      handler(newVal) {
        this.statisticsData = newVal;
      },
      immediate: true,
    },
    readNum: {
      handler(val) {
        this.notRead = val !== "已读" ? val : localStorage.getItem("notRead");
      },
      immediate: true,
    },
    avatar: {
      handler(val) {
        if (JSON.parse(localStorage.getItem("userinfo"))) {
          if (!val && !JSON.parse(localStorage.getItem("userinfo")).avatar) {
            this.headPortrait = require("../../assets/pc/images/menu/headpic.png");
          } else {
            this.headPortrait =
              val || JSON.parse(localStorage.getItem("userinfo")).avatar;
          }
        } else {
          this.headPortrait = require("../../assets/pc/images/menu/headpic.png");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less">
.dropdown-item-box {
  width: 100px;
  padding: 2px 10px !important;
  .line {
    border-bottom: 2px solid #cccccc;
  }
  .dropdown-item {
    padding: 0 10px;
    font-size: 16px;
    color: #000000 80%;
    text-align: center;
  }
  .dropdown-item:hover {
    color: rgba(48, 120, 240, 1);
  }
}
</style>

<style lang="less" scoped>
.header {
  background-color: #fff;
  .top {
    width: 100%;
    background-size: 100% 100%;
    height: 400px;
    display: flex;
    // padding: 50px 0;
    .top-box {
      width: 1200px;
      margin: 0 auto;
      .box-left {
        a {
          width: 100px;
          height: 26px;
          border: 1px solid #fff;
          margin-top: 30px;
          line-height: 26px;
          border-radius: 30px;
          color: rgba(255, 255, 255, 100);
          font-size: 12px;
          text-align: center;
          font-family: Microsoft Yahei;
        }
        .box-left-seach {
          margin-top: 20px;
          > span {
            color: #fff;
          }

          /deep/ .el-input__inner {
            border: none;
          }
          div:nth-of-type(1) {
            /deep/ .el-input {
              margin: 20px 0;
            }
            /deep/ .el-input__inner {
              border: none;
              border-radius: 0px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              height: 40px;
            }
            /deep/ .el-button {
              border-radius: 0px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              background-color: rgba(21, 110, 208, 100);
              border: 1px solid rgba(21, 110, 208, 100);
              color: #fff;
              font-size: 16px;
            }
          }
          div:nth-of-type(2) {
            color: #fff;
            font-size: 14px;
            > span {
              display: inline-block;
              width: 70px;
              height: 24px;
              text-align: center;
              line-height: 24px;
              background-color: rgba(255, 255, 255, 0.45);
              color: rgba(0, 0, 0, 0.7);
              font-size: 14px;
              // padding: 4px 10px;
              border-radius: 4px;
              margin-left: 6px;
              cursor: pointer;
            }
          }
        }
      }
      .box-right {
        .right-top {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          height: 32px;
          line-height: 32px;
          .el-avatar {
            width: 32px;
            height: 32px;
            line-height: 32px;
            margin-right: 20px;
          }
          .chat {
            width: 24px;
            height: 19px;
            margin: 0 25px;
          }
          .login {
            margin-top: 2px;
            color: #fff;
            a {
              color: #fff;
            }
            i {
              font-size: 16px;
              margin-right: 6px;
            }
          }
          .avatar.el-dropdown {
            width: 126px;
            height: 32px;
            position: relative;
            .vip {
              width: 14px;
              height: 14px;
              background-color: #fff;
              border-radius: 50%;
              position: absolute;
              bottom: -3px;
              right: -3px;
            }
          }
        }
        .right-bottom {
          width: 546px;
          background-color: rgba(255, 255, 255, 0.35);
          margin-top: 20px;
          .rectangle {
            width: 44%;
            height: 70px;
            padding: 0px 16px;
            > div {
              > div:nth-of-type(1) {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                line-height: 42px;
                text-align: center;
                background-color: rgba(41, 117, 203, 0.6);
                color: rgba(16, 16, 16, 100);
                font-size: 14px;
              }
              span {
                font-size: 14px;
              }
            }
            > div:nth-of-type(2) {
              font-weight: 600;
              font-size: 18px;
              > span {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
          .rectangle:nth-of-type(1) {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
          }
          .rectangle:nth-of-type(2) {
            border-bottom: 1px solid #ccc;
          }
          .rectangle:nth-of-type(3) {
            border-right: 1px solid #ccc;
          }
        }
      }
    }
    .left {
      padding: 38px 29px 23px 22px;
    }
    .middle {
      flex: 1;
      color: #156ed0;
      font-size: 14px;
      padding-top: 65px;
    }
    .right {
      width: 352px;
      padding: 15px 47px 0 0;
      .right-top {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        .el-avatar {
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 20px;
        }
        .chat {
          width: 24px;
          height: 19px;
          margin: 0 25px;
        }
        .login {
          color: #156ed0;
          a {
            color: #156ed0;
          }
        }
        .avatar.el-dropdown {
          width: 32px;
          height: 32px;
          position: relative;
          .vip {
            width: 14px;
            height: 14px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            bottom: -3px;
            right: -3px;
          }
        }
      }
      .right-bottom {
        text-align: right;
        padding-top: 8px;
        position: relative;
        /deep/.el-input__inner {
          width: 304px;
          height: 32px;
        }
        /deep/.el-icon-search:before {
          height: inherit;
          display: block;
          content: "";
          background: url("~@/assets/pc/images/menu/search.png") center
            no-repeat;
          background-size: 28px 28px;
        }
      }
    }
    /deep/.el-badge__content.is-fixed {
      top: 14px;
      right: -4px;
    }
  }
  .top-bgImg {
    background-image: url("../../assets/pc/images/menu/bgImg.png");
  }
  .top-bgCol {
    // padding: 20px 0;
    height: 100px;
    background: linear-gradient(0deg, #4e9ef3, #156ed0);
  }
  .menu {
    display: flex;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    height: 38px;
    line-height: 38px;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    width: 1200px;
    li {
      // width: 171px;
      width: 198px;
      border-left: 1px solid #ccc;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // &:hover{
      //   background: #4e9ef3;
      // }
      &:nth-last-child(1) {
        border-right: 1px solid #ccc;
      }
      a {
        display: block;
        width: inherit;
        color: #101010;
      }
    }
    li:hover {
      background: #74b2f5;
      a {
        color: #fff;
      }
    }
    .el-dropdown-link {
      width: 171px;
      display: block;
      width: inherit;
      font-size: 16px;
      // border: 1px solid rgba(0, 0, 0, 0);"
      color: #101010;
    }
    // .dropdown-item {
    //   padding: 8px 10px;
    //   border-bottom: 1px solid #000000 10%;
    //   font-size: 16px;
    //   color: rgba(0, 0, 0, 0.01);
    //   width: 120px;
    // }
    // .dropdown-item:hover {
    //   color: rgba(48, 120, 240, 1);
    // }
  }
}
.matching_icon {
  width: 14px;
  height: 14px;
  background-image: url("../../assets/pc/images/simpleMatching/crown.png");
  background-size: 14px 14px;
  position: absolute;
  top: 25%;
  left: -15px;
}

.choose-city {
  color: #156ed0;
}
.qrcode-wechat {
  padding: 32px 28px;
}
// .el-dropdown-menu__item:focus,
// .el-dropdown-menu__item:not(.is-disabled):hover {
//   color: #303134;
// }
.vip-class {
  background: #f6f3e7;
  border: 1px solid #e5ddbb;
}
.normal-class {
  background: #f5f5f5;
  border: 1px solid #dcdcdc;
}
.normal-class-drop {
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.normal-class-drop img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.vip-class-drop {
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.vip-class-drop img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.drop-data {
  display: flex;
  flex-direction: column;
}
.item {
  // margin-top: 10px;
  margin-right: 60px;
  color: #fff;
  // width: 14px;
  // height: 14px;
  i {
    font-size: 16px;
  }
}
/deep/.el-dropdown-menu__item {
  padding: 5px 20px;
}
/deep/ .el-tabs__nav-wrap {
  margin-top: 5px;
  margin-left: 5px;
}
.tabs_item_span {
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  width: 10%;
  margin-bottom: 15px;
}
.tabs_item_span:hover {
  color: #156ed0;
}
.user_item {
  display: flex;
  width: 180px;
  height: 64px;
  border-radius: 5px;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  // margin-left: 40px;
}
.xtb {
  position: relative;
  top: 10px;
  left: 55px;
  color: #cdcdcd;
}
.avatar-login {
  color: #fff;
  margin-top: 2px;

  i {
    margin-top: -4px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
  }
}
.header .top .right .right-top .el-avatar[data-v-3907a6ca] {
  margin-right: 20px;
}
/deep/ .el-dropdown-link.el-dropdown-selfdefine {
  display: inline-block;
}

.selected_text{
  background: #156ed0;
  a {
    color: #fff !important;
  }
}
</style>
