export default {
  namespaced: true,
  state: () => ({
    router_name: 'information',
    customerId: '', // 详情页获取全部标签的id
    submit_id: '', // 详情页保存调用接口的id
    show_advice: false, // 客户管理的弹窗是否显示搜索
    dialogShow: false,
    activeName: 'first'
  }),
  mutations: {
    set_router_name (state, name) {
      state.router_name = name;
    },
    set_customer_id (state, id) {
      state.customerId = id;
    },
    set_submit_id (state, id) {
      state.submit_id = id;
    },
    set_show_advice (state, status) {
      state.show_advice = status;
    },
    set_dialog_show (state, show) {
      state.dialogShow = show;
    },
    set_activeName (state, data) {
      state.activeName = data;
    }
  },
  actions: {
  },
  getters: {}
};
