<template>
  <div id="app" class="app" :class="className">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
      <!-- 这里因为路由层级过多所以不使用Include -->
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import getLocation from '@/mixins/getLocation.js';
import getLogin from '@/mixins/getLogin.js';
import { request } from './network';
import { mapMutations } from 'vuex';
export default {
  mixins: [ getLocation, getLogin ],
  data () {
    return {
      className: ''
    };
  },
  created () {
    this.mixingetInfoData(request);
    this.isLoginStatus(true);
    setInterval(() => {
      this.isLoginStatus(true);
    }, 60000);
    sessionStorage.setItem('cityTabs', JSON.stringify([])); // 初始化
  },
  mounted () {
    addEventListener('resize', this.onResize);
    this.onResize();
    this.refreshPage();
    var _hmt = _hmt || [];
    var hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?b5b73b4ab08a7f1c90b4d7613b6c6725';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  },
  methods: {
    ...mapMutations('login', [ 'isLoginStatus' ]),
    onResize () {
      // const vw = document.documentElement.clientWidth || document.body.clientWidth
      // if (vw < 1200) {
      //   this.className = 'width'
      // } else {
      //   this.className = ''
      // }
    },
    // 更新版本之后自动刷新页面
    refreshPage () {
      this.$http.get(process.env.VUE_APP_HTTP_URL + '/version.json', {}).then(res => {
        // console.log('检查版本', res.data.version);
        const localVersion = localStorage.getItem('version');
        if (localVersion === null || localVersion === undefined) {
          localStorage.setItem('version', res.data.version);
        } else {
          if (localVersion !== res.data.version) {
            // this.$confirm('检测到新版本，刷新页面！！！', '提示', {
            //   confirmButtonText: '确定'
            // }).then(() => {
            //   this.$router.go(0);
            //   localStorage.setItem('version', res.data.version);
            // });
            this.$router.go(0);
            localStorage.setItem('version', res.data.version);
          }
        }
      });
    }
  }
};
</script>

<style lang="less">
.width {
  width: 1200px;
}
.app {
  background-color: #fff;
  // -webkit-filter : grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: grayscale(100%);
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
</style>
