import { request } from '@/network';
import dict from './map';
const {
  match, lastMatch, matchRecord, matchRecordResult, searchResult, projectPage, areaByCode,
  basicDataList, bigCategoryList, nextStep, prevStep, cert, orgType, hasPlan, hasDeclare,
  exportResult, getMemberExclusivePhoneApi, getQQApi, getWechatApi, getWechatOfficialAccountApi,
  accountInformationApi
} = dict;
// 开始匹配
export function getMatch (params) {
  return request({
    method: 'post',
    url: match,
    data: params
  }, 'POST');
}
// 获取搜索框的历史记录
export function getLastMatch (params) {
  return request({
    method: 'GET',
    url: lastMatch,
    params: params
  });
}
// 获取匹配记录
export function getMatchRecord (params) {
  return request({
    method: 'GET',
    url: matchRecord,
    params: params
  });
}
// 获取匹配记录的项目列表
export function getMatchRecordResult (params) {
  return request({
    method: 'GET',
    url: matchRecordResult,
    params: params
  });
}

export function getSearchResult (params) {
  return request({
    method: 'POST',
    url: searchResult,
    data: params
  });
}
// 获取项目的分页数据
export function getProjectPage (params) {
  return request({
    method: 'GET',
    url: projectPage,
    params: params
  });
}

export function getAreaByCode (params) {
  return request({
    method: 'GET',
    url: areaByCode,
    params: params
  });
}

export function getOtherBasicDataList () {
  return request({
    method: 'GET',
    url: basicDataList
  });
}

export function getBigCategoryList (params) {
  return request({
    method: 'GET',
    url: bigCategoryList,
    params: params
  });
}

export function getNextStep (params) {
  return request({
    method: 'post',
    url: nextStep,
    data: params
  }, 'POST');
}

export function getPrevStep (params) {
  return request({
    method: 'get',
    url: prevStep,
    params: params
  });
}

export function searchCertification (params) {
  return request({
    method: 'get',
    url: cert,
    params: params
  });
}
export function searchCompanyOrgType (params) {
  return request({
    method: 'get',
    url: orgType,
    params: params
  });
}
// 纳入计划
export function addHasPlan (params) {
  return request({
    method: 'post',
    url: hasPlan,
    data: params
  });
}
// 申报
export function addHasDeclare (params) {
  return request({
    method: 'post',
    url: hasDeclare,
    data: params
  }, 'POST');
}

export function getExportResult (params) {
  return request({
    method: 'get',
    url: exportResult,
    params: params
  });
}

export function getMemberExclusivePhone () {
  return request({
    method: 'get',
    url: getMemberExclusivePhoneApi,
  });
}

export function getQQ () {
  return request({
    method: 'get',
    url: getQQApi,
  });
}

export function getWechat () {
  return request({
    method: 'get',
    url: getWechatApi,
  });
}

export function getWechatOfficialAccount () {
  return request({
    method: 'get',
    url: getWechatOfficialAccountApi,
  });
}

export function accountInformation (id) {
  return request({
    method: 'get',
    url: accountInformationApi + `?id=${id}`,
  });
}
