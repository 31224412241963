import index from '@/views/pc/public.vue';

/*
* 配置登录权限：在meta属性内添加"requireAuth"、"roles"
* requireAuth: true时表示这个路由需要进行路由守卫的判断————登录状态才能进入，否则强行跳转登录界面
* roles： ['role1','role2']这里表示当用户属于roles数组内的角色才有权访问该路由（2021-11-25还未确定是否需要角色权限）
* */

export default [
  {
    path: '/',
    redirect: '/index',
    component: index,
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/pc/index/index.vue')
      },
      {
        path: '/index/index',
        name: 'index',
        redirect: '/index',
        component: () => import('@/views/pc/index/index.vue'),
        children: [
          {
            path: '/index',
            component: () => import('@/views/pc/index/index.vue')
          }
        ]
      },
      {
        path: '/newscenter',
        name: 'newscenter',
        component: () => import('@/views/pc/index/indexNewsCenter.vue')
      },
      {
        path: '/newsdetail',
        name: 'newsdetail',
        component: () => import('@/views/pc/index/indexNewsDetail.vue')
      },
      {
        path: '/indexpolicy',
        name: 'indexpolicy',
        component: () => import('@/views/pc/index/indexPolicyTopics.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pc/login/login.vue'),
        redirect: '/login/loginbyverification',
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: 'normal',
            name: 'normal',
            component: () => import('@/views/pc/login/loginComp/loginRightNormal.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'loginbyverification',
            name: 'loginbyverification',
            component: () => import('@/views/pc/login/loginComp/loginRightVerification.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'loginbywechat',
            name: 'loginbywechat',
            component: () => import('@/views/pc/login/loginComp/loginRightWechat.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'forgetpaw',
            name: 'forgetpaw',
            component: () => import('@/views/pc/login/loginComp/loginForgetPaw.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'register',
            name: 'register',
            component: () => import('@/views/pc/login/loginComp/loginRightRegister.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'loginBind',
            name: 'loginBind',
            component: () => import('@/views/pc/login/loginComp/loginBind.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'bindPhone',
            name: 'bindPhone',
            component: () => import('@/views/pc/login/loginComp/bindPhone.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'loginPhoneBind',
            name: 'loginPhoneBind',
            component: () => import('@/views/pc/login/loginComp/loginPhoneBind.vue'),
            meta: {
              requireAuth: true
            }
          }
        ]
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('@/views/pc/policy/policy.vue'),
        redirect: '/policy/tongzhi',
        // meta: {
        //   keepAlive: true
        // },
        children: [
          {
            path: 'tongzhi',
            component: () => import('@/views/pc/policy/tongzhi.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: 'fagui',
            component: () => import('@/views/pc/policy/fagui.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: 'test',
            component: () => import('@/views/pc/policy/test.vue')
          }
        ]
      },
      {
        path: '/cidian',
        name: 'cidian',
        redirect: '/cidian/index',
        component: () => import('@/views/pc/cidian/index.vue'),
        children: [
          {
            path: 'index',
            name: '/cidian/index',
            component: () => import('@/views/pc/cidian/index.vue')
          }
        ]
      },
      {
        path: '/eventServices',
        name: 'eventServices',
        redirect: '/eventServices/index',
        component: () => import('@/views/pc/eventServices/index.vue'),
        children: [
          {
            path: 'index',
            name: '/eventServices/index',
            component: () => import('@/views/pc/eventServices/index.vue')
          }
        ]
      },
      {
        path: '/matching',
        name: 'matching',
        component: () => import('@/views/pc/matching/index.vue'),
        redirect: '/matching/searchCompany',
        meta: { requireAuth: true },
        children: [
          {
            path: 'searchCompany',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/matching/searchCompany/index.vue')
          },
          {
            path: 'infoCompany',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/matching/infoCompany/index.vue')
          },
          {
            path: 'chooseCompany',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/matching/chooseCompany/index.vue')
          },
          {
            path: 'resultCompany',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/matching/resultCompany/index.vue')
          }
        ]
      },
      {
        path: '/simpleMatching',
        name: 'simpleMatching',
        component: () => import('@/views/pc/simpleMatching/index.vue'),
        redirect: '/simpleMatching/search',
        meta: { requireAuth: true },
        children: [
          {
            path: 'search',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/simpleMatching/search/index.vue')
          },
          {
            path: 'result',
            meta: { requireAuth: true },
            component: () => import('@/views/pc/simpleMatching/result/index.vue')
          },
          {
            path: 'record',
            meta: {
              requireAuth: true,
              noShowBreadcrumb: true
            },
            component: () => import('@/views/pc/simpleMatching/record/index.vue')
          }
        ]
      },
      {
        path: '/push',
        name: 'push',
        component: () => import('@/views/pc/push/index.vue'),
        redirect: '/push/adaptationproject',
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: 'adaptationproject',
            component: () => import('@/views/pc/push/adaptationProject.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'followpolicy',
            component: () => import('@/views/pc/push/followPolicy.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'mysubscribe',
            component: () => import('@/views/pc/push/mySubscribe.vue'),
            meta: {
              requireAuth: true
            }
          }
        ]
      },
      {
        path: '/member',
        name: 'member',
        component: () => import('@/views/pc/member/member.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/cidian/nounexplain',
        component: () => import('@/views/pc/cidian/nounExplain.vue')
      },
      {
        path: '/eventServices/activityDetails',
        name:'activityDetails',
        component: () => import('@/views/pc/eventServices/activityDetails.vue')
      },
      {
        path: '/eventServices/messageDetails',
        name:'servicesDetails',
        component: () => import('@/views/pc/eventServices/messageDetails.vue')
      },
      {
        path: '/push/messageDetails',
        name:'pushDetails',
        component: () => import('@/views/pc/push/messageDetails.vue')
      },
      {
        path: '/organization',
        name: 'organization',
        component: () => import('@/views/pc/organization/organization.vue'),
        redirect: '/orgapply',
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: '/orgapply',
            name: 'orgapply',
            component: () => import('@/views/pc/organization/organizeComp/orgApply.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/orgapplysubmit',
            name: 'orgapplysubmit',
            component: () => import('@/views/pc/organization/organizeComp/orgApplySubmit.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/orgsubmitsuccess',
            name: 'orgsubmitsuccess',
            component: () => import('@/views/pc/organization/organizeComp/orgSubmitSuccess.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '/orgapplyresult',
            name: 'orgapplyresult',
            component: () => import('@/views/pc/organization/organizeComp/orgApplyResult.vue'),
            meta: {
              requireAuth: true
            }
          }
        ]
      },
      {
        path: '/formaldetails',
        name: 'formaldetails',
        component: () => import('@/views/pc/policy/formalDetails/formalDetails.vue'),
        redirect: '/formaldetails/tongzhi',
        children: [
          {
            path: 'tongzhi',
            component: () => import('@/views/pc/policy/formalDetails/tongzhi.vue')
          },
          {
            path: 'wenji',
            component: () => import('@/views/pc/policy/formalDetails/wenji.vue')
          },
          {
            path: 'jiedu',
            component: () => import('@/views/pc/policy/formalDetails/jiedu.vue')
          },
          {
            path: 'xinxi',
            component: () => import('@/views/pc/policy/formalDetails/xinxi.vue')
          },
          {
            path: 'mingdan',
            component: () => import('@/views/pc/policy/formalDetails/mingdan.vue')
          }
        ]
      },
      {
        path: '/faguidetails',
        name: 'faguidetails',
        component: () => import('@/views/pc/policy/faguiDetails/faguiDetails.vue'),
        redirect: '/faguidetails/tongzhi',
        children: [
          {
            path: 'tongzhi',
            component: () => import('@/views/pc/policy/faguiDetails/tongzhi.vue')
          },
          {
            path: 'wenji',
            component: () => import('@/views/pc/policy/faguiDetails/wenji.vue')
          }
        ]
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/pc/personal/personal.vue'),
        redirect: '/personal/perfect',
        meta: {
          requireAuth: true
        },
        children: [
          {
            path: 'perfect',
            component: () => import('@/views/pc/personal/perfect.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'read',
            component: () => import('@/views/pc/personal/read.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'info',
            component: () => import('@/views/pc/personal/info.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'info/messagedetail',
            component: () => import('@/views/pc/personal/components/messageDetail.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'account',
            component: () => import('@/views/pc/personal/account.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'activity',
            component: () => import('@/views/pc/personal/activity.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'activityRecord',
            component: () => import('@/views/pc/personal/activityRecord.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'declare',
            component: () => import('@/views/pc/personal/declare.vue'),
            meta: {
              requireAuth: true
            }
          }
        ]
      },
      {
        path: '/agencies',
        name: 'agencies',
        component: () => import('@/views/pc/agencies/index.vue')
      },
      {
        path: '/hint',
        name: 'hint',
        component: () => import('@/views/pc/login/hint.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/pc/about/index.vue')
      },
      {
        path: '/videoTutorial',
        name: 'videoTutorial',
        component: () => import('../views/pc/videoTutorial/index.vue')
      },
      {
        path: '/policySearchNotice',
        name: 'policySearchNotice',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/pc/policySearchNotice/index.vue')
      },
      {
        path: '/policySearchReg',
        name: 'policySearchReg',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/pc/policySearchReg/index.vue')
      }
    ]
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: () => import('@/views/pc/backstage/index.vue'),
    children: [
      {
        path: '/backstage',
        name: 'information',
        component: () => import('../views/pc/backstage/components/information/index.vue')
      },
      {
        path: '/customer',
        name: 'customer',
        component: () => import('../views/pc/backstage/components/customer/index.vue')
      },
      {
        path: '/institutions',
        name: 'institutions',
        component: () => import('../views/pc/backstage/components/institutions/index.vue')
      },
      {
        path: '/matchLabel',
        name: 'matchLabel',
        component: () => import('../views/pc/backstage/components/matchLabel/index.vue')
      },
      {
        path: '/advice',
        name: 'advice',
        component: () => import('../views/pc/backstage/components/advice/index.vue')
      },
      {
        path: '/details',
        name: 'details',
        component: () => import('../views/pc/backstage/components/details/index.vue')
      }
    ]
  },
  {
    path: '/adviceTable',
    name: 'adviceTable',
    component: () => import('../views/pc/backstage/components/advice/components/adviceTable.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/pc/business/index.vue')
  },
  {
    path: '/freeOpenVip',
    name: 'freeOpenVip',
    component: () => import('../views/pc/freeOpenVip/index.vue')
  },
  {
    path: '/LargeDataScreen',
    name: 'LargeDataScreen',
    component: () => import('@/views/pc/large_data_screen/index.vue')
  }
];
