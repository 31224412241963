export default {
  namespaced: true,
  state: () => ({
    chooseCity: '',
    selectCity: ''
  }),
  mutations: {
    setCity (state, payload) {
      state.chooseCity = payload;
    },
    setSelectCity (state, value) {
      state.selectCity = value;
      localStorage.setItem('selectCity', value);
    }
  },
  actions: {
  },
  getters: {
  }
};
